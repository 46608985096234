export interface SRCMReportSearchDTO {
  fiscalYear?: string[];
  geographicalUnit?: string[];
  month?: string[];
  country?: string[];
  componyCode?: string[];
  spendCategory?: string[];
  onboardedFY?: string[];
  onboardedMonth: string[];
  timeZone?: number;
}

export function createReportDTO(): SRCMReportSearchDTO {
  return {
    fiscalYear: [],
    geographicalUnit: [],
    month: [],
    country: [],
    componyCode: [],
    spendCategory: [],
    onboardedFY: [],
    onboardedMonth: [],
  };
}
