import { BuyerACLPermissionService } from "./../../../services/buyerACLPermission.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import {
  BuyerRoleACLButton,
  BuyerRoleACLPageName,
  RoleName,
} from "src/app/interfaces/buyer";
import { DashBoardService } from "../../../services/dashboard.service";
import {
  SpendCategoryGroup,
  AP_ReviewStatus,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import { OnBoardingService } from "../../../services/onboarding.service";
import { RiskScore } from "../../../../../interfaces/process";
import {
  DialogService,
  ButtonCommands,
  DialogType,
} from "src/app/services/dialog.service";
import { BuyerService } from "src/app/services/buyer.service";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { SapResultModel } from "src/app/interfaces/mapping";
import { BuyerChangeRequestDTO } from "src/app/interfaces/changeRequest";
import {
  ProcessNames,
  SetupProcess,
  ProcessStatus,
} from "src/app/interfaces/workflow";
import { SupplierSetupProcessService } from "../../../services/supplierSetupProcess.service";
import { MigrateSetupProcessService } from "../../../services/migrateSetupProcess.service";
import { HoldOnService } from "src/app/services/HoldOn";
import { async } from "@angular/core/testing";
import { AuthService } from "src/app/services/auth/auth.service";
@Component({
  selector: "app-buyer-onboarding-process",
  templateUrl: "./onboarding-process-component.html",
  styleUrls: ["./onboarding-process-component.sass"],
})
export class BuyerOnboardingProcessComponent implements OnInit {
  sapResult: SapResultModel;
  section1_show = true;
  section2_show = true;
  section3_show = true;

  get onboardedDate(): Date {
    return moment(this.onboardingService.supplierModel.Mapping.InvitedDate)
      .utc()
      .toDate();
  }
  private RiskAssessmentStatus: string;
  private BI_Score: string;
  supplierCode: string;
  requestorName = "";
  supplierCompanyName = "";
  circleSteps = [];
  currTitleStep: any;
  requestOpen = false;
  showEditLink = false;
  showInvitaionButton = false;
  showRiskAssessmentPart = false;
  showApplyHoldOnButton = false;
  // apReviewStatus: string
  // isRequestorRejected: Boolean = false
  showChangeRequest = false;
  showEditHoldButton = false;
  showEditHold = false;
  hasActiveHold = false;
  showApplyHold = false;
  applyHoldBtn = false;
  editHoldBtn = false;

  eStatus = SupplierStatus;
  onboardingContentTemplate = "";
  supplierInfo: any = {};
  SpendCategoryGroup = SpendCategoryGroup;
  AP_ReviewStatus = AP_ReviewStatus;
  DataPrivacyRisk = "";
  InfosecRisk = "";
  applyHold: any = {};
  isMigrated = false;
  isOnBoarded = false;
  isTerminated = false;
  QcReviewComplete = false;

  private sendInvitationTitle = "Send Invitation";
  private replaceEmailValue = "";
  private replaceEmailTitle = "Replace POC Email";
  private RiskScore = RiskScore;
  private riskRelationshipNum = "";
  onboardingService: OnBoardingService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashBoardService: DashBoardService,
    private _onboardingService: OnBoardingService,
    private holdOnService: HoldOnService,
    private buyerACLPermissionService: BuyerACLPermissionService,
    private dialogService: DialogService,
    private buyerService: BuyerService,
    private loadingService: LoadingService,
    private supplierSetupProcessService: SupplierSetupProcessService,
    private migrateSetupProcessService: MigrateSetupProcessService,
    private holdService: HoldOnService,
    private authService: AuthService
  ) {
    this.onboardingService = _onboardingService;
    this.dashBoardService.isDashBoard = false;
    if (this.dashBoardService.isSearchInTsm) {
      this.dashBoardService.isTsmEnterOnboarding = true;
    }
    this.onboardingService.profileStage = "process";
    this.dashBoardService.title = "Supplier Onboarding Process";
  }

  async ngOnInit() {
    this.clearStepsInfo();
    this.supplierCode = this.route.snapshot.paramMap.get("supplierCode");
    this.dashBoardService.supplierCode = this.supplierCode;
    this.onboardingService.supplierCode = this.supplierCode;
    this.loadingService.showLoading();
    await this.onboardingService.loadSupplierRequestForm();
    const [userTasks, riskAssessment, crFlag, applyHold] = await Promise.all([
      this.onboardingService.loadUserTasks(),
      this.onboardingService.loadRiskAssessment(),
      this.isRequestChangeOpen(),
      this.holdService.getLatestActiveHoldonBysupplierCode(
        this.onboardingService.supplierModel.Mapping.supplierCode
      ),
    ]);
    if (this.onboardingService.riskAssessment) {
      this.riskRelationshipNum =
        this.onboardingService.riskAssessment.RelationshipNumber;
    }
    if (
      this.onboardingService.supplierModel.RequestForm &&
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo
    ) {
      this.requestorName =
        !!this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.IsEmployee ? 
        this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.BehalfEid : 
        this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.RequestorEid;
      this.supplierCompanyName =
        this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCompanyName;
    }
    // console.log(
    //   this.onboardingService.getUserTaskByKey(
    //     ProcessNames.generic_supplierSetupSubProcess,
    //     SetupProcess.SST_rejectToBuyer
    //   )
    // );
    // console.log(
    //   this.onboardingService.getUserTaskByKey(
    //     ProcessNames.generic_supplierSetup,
    //     SetupProcess.supplier_accepted
    //   )
    // );
    this.requestOpen = crFlag;
    this.applyHold = applyHold;
    this.isMigrated = this.onboardingService.supplierModel.Mapping.IsMigrated;
    this.isOnBoarded =
      this.onboardingService.supplierModel.Mapping.status ===
      SupplierStatus.onboarded;
    this.isTerminated =
      this.onboardingService.supplierModel.Mapping.status ===
      SupplierStatus.terminated;
    this.QcReviewComplete = this.onboardingService.checkAPTaskIsComplete(
      ProcessNames.generic_supplierSetupSubProcess,
      SetupProcess.QC_review
    );
    if (this.onboardingService.riskAssessment) {
      this.DataPrivacyRisk =
        this.onboardingService.riskAssessment.DataPrivacyRisk;
      this.InfosecRisk = this.onboardingService.riskAssessment.InfosecRisk;
    }
    if (this.onboardingService.supplierModel.Mapping.IsMigrated) {
      await this.loadMigrateSetupProcess();
    } else {
      await this.loadSupplierSetupProcess();
    }
    this.loadACL();
    // Whether history link to display in dashboard header.
    if (this.onboardingService.supplierModel.Mapping.IsMigrated) {
      this.dashBoardService.isMigrated = true;
    } else {
      this.dashBoardService.isMigrated = false;
    }
    if (this.applyHold.data.length > 0) {
      setTimeout(() => {
        // eslint-disable-next-line max-len
        this.dialogService.dialog(
          `Please be aware this Supplier Profile has been put on hold. This means there's information outstanding from the Requester and/or Supplier that potentially exposes Accenture to risk. To find more information please navigate to this Suppliers Transaction History and reach out to the user that applied the hold.`,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        );
      });
    }
    this.loadingService.hideLoading();
  }

  loadACL() {
    const buttonIndex =
      this.buyerACLPermissionService.buttonPermissions.indexOf(
        BuyerRoleACLButton.RequestorformEditButton
      );
    this.showEditLink = buttonIndex === -1 ? false : true;
    const migrateInvitationIndex =
      this.buyerACLPermissionService.buttonPermissions.indexOf(
        BuyerRoleACLButton.MigrationInvitaionButton
      );
    this.showInvitaionButton = migrateInvitationIndex === -1 ? false : true;
    const riskAssessmentIndex =
      this.buyerACLPermissionService.pagePermissions.indexOf(
        BuyerRoleACLPageName.RiskAssessmentSummaryPage
      );
    this.showRiskAssessmentPart = riskAssessmentIndex === -1 ? false : true;
    const editHoldIndex =
      this.buyerACLPermissionService.buttonPermissions.indexOf(
        BuyerRoleACLButton.EditHoldButton
      );
    this.showEditHoldButton = editHoldIndex === -1 ? false : true;
    const applyHoldOnIndex =
      this.buyerACLPermissionService.buttonPermissions.indexOf(
        BuyerRoleACLButton.ApplyHoldonButton
      );
    this.showApplyHoldOnButton = applyHoldOnIndex === -1 ? false : true;
  }

  get ShowEditButton() {
    return (
      this.showEditLink &&
      this.onboardingService.getUserTaskByKey(
        ProcessNames.generic_supplierSetupSubProcess,
        SetupProcess.SST_rejectToBuyer
      )
    );
    // && this.apReviewStatus === AP_ReviewStatus.SST_reject
    // && this.isRequestorRejected
    // && this.onboardingService.supplierModel.Mapping.status !== SupplierStatus.onboarded
  }

  get showRequestorChangeRequestBtn() {
    if (!this.onboardingService.supplierModel) {
      return false;
    }
    return (
      this.onboardingService.supplierModel.Mapping.status ===
        SupplierStatus.onboarded &&
      !this.requestOpen &&
      this.onboardingService.supplierModel.Mapping.buyerChangeRequestStatus !==
        ProcessStatus.pending
    );
  }

  get showEditRequestBtn() {
    // console.log(this.onboardingService.getUserTaskByKey(ProcessNames.generic_supplierSetup, SetupProcess.supplier_accepted))
    return (
      this.showEditLink &&
      (this.authService.passport.buyer.RoleCode === RoleName.Requestor ||
        this.authService.passport.buyer.RoleCode ===
          RoleName.SourcingCategoryManagement ||
        this.authService.passport.buyer.RoleCode === RoleName.BuyerAdmin ||
        this.authService.passport.buyer.RoleCode === RoleName.SystemAdmin ||
        this.onboardingService.supplierModel.Mapping.requestor.createdBy ===
          this.authService.passport.buyer.Email) &&
      this.onboardingService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.requestor_draft
      ) &&
      !this.onboardingService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.supplier_accepted
      ) &&
      !(
        this.onboardingService.supplierModel.Mapping.status ===
        SupplierStatus.terminated
      )
    );
  }

  get showEditHoldBtn() {
    if (this.applyHoldBtn) {
      return true;
    }
    return (
      this.showEditHoldButton &&
      !this.editHoldBtn &&
      this.applyHold.data.length > 0
    );
  }

  checkResult(e) {
    if (e) {
      this.applyHoldBtn = false;
      this.editHoldBtn = true;
    }
  }

  get showApplyHoldBtn() {
    if (this.editHoldBtn) {
      return true;
    }
    if (
      !this.isMigrated &&
      !this.isOnBoarded &&
      !this.isTerminated &&
      !this.QcReviewComplete &&
      this.showApplyHoldOnButton
    ) {
      return (
        !(this.applyHold.data && this.applyHold.data.length > 0) &&
        !this.applyHoldBtn
      );
    }
    return false;
  }

  applyHoldResult(e) {
    if (e) {
      this.applyHoldBtn = true;
      this.editHoldBtn = false;
    }
  }

  async isRequestChangeOpen() {
    let hasOpenedCR = false;
    const requestorChangeRequestList: any =
      await this.onboardingService.loadChangeRequestList();
    if (requestorChangeRequestList.length !== 0) {
      requestorChangeRequestList.forEach((item: BuyerChangeRequestDTO) => {
        if (item.openFlag === true) {
          hasOpenedCR = true;
        }
      });
    }
    return hasOpenedCR;
  }

  clearStepsInfo() {
    this.supplierSetupProcessService.clear();
    this.migrateSetupProcessService.clear();
  }

  navTo() {
    this.router.navigate([`buyer/onboarding/${this.supplierCode}/step1`]);
  }

  viewProfileForm() {
    this.loadingService.showLoading();
    this.router.navigate([`buyer/supplier/${this.supplierCode}`]);
  }

  backToCompleted() {
    this.router.navigate([`buyer/onboarding/${this.supplierCode}/step4`]);
  }

  async sendInvitation() {
    await this.dialogService.sendInvitation(DialogType.sendInvitation);
  }
  async replaceEmail() {
    await this.dialogService.replaceEmail(DialogType.replace);
  }

  showRequestorCRDialog() {
    this.showChangeRequest = !this.showChangeRequest;
  }

  showApplyHoldDialog() {
    this.showApplyHold = !this.showApplyHold;
  }

  showEditHoldDialog() {
    this.showEditHold = !this.showEditHold;
  }

  hiddenDialog(e) {
    this.showChangeRequest = e;
    this.showApplyHold = e;
    this.showEditHold = e;
  }

  checkChangeResult(e) {
    if (e) {
      this.requestOpen = true;
    }
  }

  // ################## handle supplier setup process ##################

  // load supplier setup process
  async loadSupplierSetupProcess() {
    await this.supplierSetupProcessService.loadSupplierSetupProcess();
    this.circleSteps = this.supplierSetupProcessService.circleSteps;
    this.currTitleStep = this.supplierSetupProcessService.currTitleStep;
    this.RiskAssessmentStatus =
      this.supplierSetupProcessService.RiskAssessmentStatus;
    this.BI_Score = this.supplierSetupProcessService.BI_Score;
  }

  // ################## handle migrate setup process ##################

  // load migrate setup process
  async loadMigrateSetupProcess() {
    await this.migrateSetupProcessService.loadMigrateSetupProcess();
    this.circleSteps = this.migrateSetupProcessService.circleSteps;
    this.currTitleStep = this.migrateSetupProcessService.currTitleStep;
  }
  async terminateSupplier() {
    await this.dialogService.terminateSupplier("");
  }

  get isShowTerminateSupplier() {
    if (!this.onboardingService.supplierModel) {
      return;
    }
    let isShownToRequestor = false;
    let isTerminatedStatus = false;
    if (
      this.onboardingService.supplierModel.Mapping.requestor.createdBy ===
        this.authService.passport.buyer.Email &&
      this.currTitleStep &&
      (this.currTitleStep.status === SetupProcess.requestor_draft ||
        this.currTitleStep.status === SetupProcess.supplier_accepted)
    ) {
      isShownToRequestor = true;
    }
    if (
      this.onboardingService.supplierModel.Mapping.status ===
      SupplierStatus.terminated
    ) {
      isTerminatedStatus = true;
    }
    return (
      !isTerminatedStatus &&
      (this.authService.passport.buyer.RoleCode ===
        RoleName.SystemAdmin ||
        this.authService.passport.buyer.RoleCode ===
          RoleName.APSupplierSetupTeam ||
        this.authService.passport.buyer.RoleCode ===
          RoleName.APQualityControl ||
        this.authService.passport.buyer.RoleCode === RoleName.BuyerAdmin ||
        isShownToRequestor)
    );
  }

  backToDashboard() {
    if (this.dashBoardService.isTsmEnterOnboarding) {
      this.router.navigate([
        `buyer/search/${this.dashBoardService.tsmKey1}/${this.dashBoardService.tsmKey2}`,
      ]);
    } else {
      this.router.navigate([`buyer`]);
    }
  }
  gotobackonkeydown(event) {
    if(event.keyCode == 13){
      this.backToDashboard()
    }
  }
}
