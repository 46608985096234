import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ThemePalette } from "@angular/material/core";
import { SupplierService } from "src/app/services/supplier.service";
import { ManagementService } from "../../../services/management.service";
import { SupplierUser } from "src/app/interfaces/supplier";
import { AuthService } from "src/app/services/auth/auth.service";

enum SupplierStatus {
  All = "All",
  Active = "Active",
  Inactive = "Inactive",
}

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.sass"],
})
export class SupplierUsersComponent implements OnInit {
  users: Array<SupplierUser> = [];
  roles = [];
  color: ThemePalette = "primary";
  currentUser: any;
  supplierStatus = SupplierStatus;
  itemsPerPage = 12;
  currentPage = 1;
  currentStatus: string;
  supplierCode: string;

  get searchCondition() {
    return this.managementService.searchCondition;
  }

  @ViewChild("menuDrop") menuPanel: ElementRef;
  @ViewChild("menuButton") menuButton: ElementRef;

  roleName(roleCode): string {
    const role: any = this.roles.find((r: any) => r.RoleCode === roleCode);
    if (role) {
      return role.RoleName;
    } else {
      return "";
    }
  }

  get userList() {
    this.managementService.users = this.users;
    let users = this.users;

    if (this.searchCondition && this.searchCondition !== "") {
      users = users.filter((u) => u.Name.includes(this.searchCondition));
    }

    if (this.currentStatus === this.supplierStatus.Active) {
      users = users.filter((u) => u.Enabled);
    }
    if (this.currentStatus === this.supplierStatus.Inactive) {
      users = users.filter((u) => !u.Enabled);
    }

    return users;
  }

  constructor(
    private router: Router,
    private supplierService: SupplierService,
    private managementService: ManagementService,
    private auth: AuthService,
    private rout: ActivatedRoute
  ) { }

  ngOnInit() {
    this.managementService.title = "Supplier User Management";
    this.managementService.searchCondition = "";
    this.supplierCode = this.rout.snapshot.paramMap.get("supplierCode");
    this.currentStatus = this.supplierStatus.All;

    this.loadData();
  }

  show(user) {
    this.currentUser = user;
    this.currentUser.showMenu = true;
  }

  @HostListener("document:click", ["$event"])
  documentClick(evt) {
    if (this.menuPanel) {
      // eslint-disable-next-line import/no-deprecated
      if (
        this.menuButton &&
        this.menuButton.nativeElement.contains(event.target)
      ) {
        return;
      }
      this.currentUser.showMenu = false;
    }
  }

  async loadData() {
    this.supplierService
      .supplierUsers(this.supplierCode)
      .then((data: any) => (this.users = data.data));

    this.supplierService
      .supplierRoles()
      .then((data: any) => {
        this.roles = data.data;

        setTimeout(() => {
          const usersdiv = document.getElementsByClassName("my-pagination")[0];
          if (usersdiv) {
            const userscontrol = usersdiv.childNodes[0];
            if (userscontrol) {
              const userstemplate = userscontrol.childNodes[0];
              if (userstemplate) {
                const usersul = userstemplate.childNodes[0];
                if (usersul) {
                  for (let i = 0; i < usersul.childNodes.length; i++) {
                    let usersli = usersul.childNodes[i];
                    if (usersli.nodeName == 'LI') {
                      (usersli as any).role = "group";
                    }
                  }
                }
              }
            }
          }
        });
      });
  }

  view(user: SupplierUser) {
    this.router.navigate([
      `/buyer/supplierManagement/user-edit/view/${user.Email}/${this.supplierCode}`,
    ]);
  }

  edit(user: SupplierUser) {
    this.router.navigate([
      `/buyer/supplierManagement/user-edit/edit/${user.Email}/${this.supplierCode}`,
    ]);
  }

  active(user) { }

  deactive(user) { }

  pageChange(event) {
    this.currentPage = event;
  }
}
