import { RiskAssessmentStatus, SupplierStatus } from "./mapping";
import { SetupProcess, MigrateProcess } from "./workflow";

export enum MigratedTask {
  migrate = "Migrated",
}

export const SupplierSetupSteps = {
  [SetupProcess.requestor_draft]: {
    status: SetupProcess.requestor_draft,
    header: "Pending",
    title: "Requestor Draft",
    intro: "Requestor has completed Requestor form",
    body: "Requestor has completed Requestor form",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.requestor_draft.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.requestor_draft.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.requestor_draft.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.requestor_draft.body",
  },
  [SetupProcess.supplier_accepted]: {
    status: SetupProcess.supplier_accepted,
    header: "Pending",
    title: "Supplier Accepted Invitation",
    intro: "Supplier Accepted Invitation",
    body: "Supplier Accepted Invitation",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.supplier_accepted.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.supplier_accepted.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.supplier_accepted.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.supplier_accepted.body",
  },
  [SetupProcess.profile_draft]: {
    status: SetupProcess.profile_draft,
    header: "Pending",
    title: "Supplier Draft",
    intro: "Supplier completed Supplier Draft",
    body: "Supplier completed Supplier Draft",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.profile_draft.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.profile_draft.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.profile_draft.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.profile_draft.body",
  },
  [SetupProcess.profile_resubmit]: {
    status: SetupProcess.profile_resubmit,
    header: "Pending",
    title: "Supplier Draft",
    intro: "Supplier Resubmits profile",
    body: "Supplier Resubmits profile",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.profile_resubmit.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.profile_resubmit.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.profile_resubmit.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.profile_resubmit.body",
  },
  [SetupProcess.SST_review]: {
    status: SetupProcess.SST_review,
    header: "Pending",
    title: "AP SST Review",
    intro: "AP SST Team review has been completed",
    body: "AP SST Team review has been completed",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.SST_review.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.SST_review.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.SST_review.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.SST_review.body",
  },
  [SetupProcess.QC_review]: {
    status: SetupProcess.QC_review,
    header: "Pending",
    title: "Final QC Review",
    intro: "AP QC Team review has been completed",
    body: "AP QC Team review has been completed",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.QC_review.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.QC_review.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.QC_review.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.QC_review.body",
  },
  [SetupProcess.BI]: {
    status: SetupProcess.BI,
    header: "Pending",
    title: "BI/BDA Risk Assessment",
    intro: "BI/BDA risk assessment has been completed",
    body: "BI/BDA risk assessment has been completed",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    templ: "BI/BDA",
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.BI.header",
    titleLanguageKey: "static.process.SupplierSetupSteps.SetupProcess.BI.title",
    introLanguageKey: "static.process.SupplierSetupSteps.SetupProcess.BI.intro",
    bodyLanguageKey: "static.process.SupplierSetupSteps.SetupProcess.BI.body",
  },
  [RiskAssessmentStatus.SAP]: {
    status: RiskAssessmentStatus.SAP,
    header: "Pending",
    title: "Integrated with SAP",
    intro: "Supplier record has been setup in SAP platform",
    body: "Supplier record has been setup in SAP platform",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.RiskAssessmentStatus.SAP.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.RiskAssessmentStatus.SAP.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.RiskAssessmentStatus.SAP.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.RiskAssessmentStatus.SAP.body",
  },
  [SetupProcess.GDPR]: {
    status: SetupProcess.GDPR,
    header: "Pending",
    title: "GDPR Risk Assessment",
    intro: "GDPR risk assessment has been completed",
    body: "GDPR risk assessment has been completed",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    templ: "GDPR",
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.GDPR.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.GDPR.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SetupProcess.GDPR.intro",
    bodyLanguageKey: "static.process.SupplierSetupSteps.SetupProcess.GDPR.body",
  },
  [RiskAssessmentStatus.IS]: {
    status: RiskAssessmentStatus.IS,
    header: "Pending",
    title: "IS Risk Assessment",
    intro: "IS risk assessment has been completed",
    body: "IS risk assessment has been completed",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    templ: "IS",
    headerLanguageKey:
      "static.process.SupplierSetupSteps.RiskAssessmentStatus.IS.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.RiskAssessmentStatus.IS.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.RiskAssessmentStatus.IS.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.RiskAssessmentStatus.IS.body",
    isLast: false
  },
  [SupplierStatus.onboarded]: {
    status: SupplierStatus.onboarded,
    header: "OnBoarded",
    title: "Completed",
    intro: "Supplier has been onboarded",
    body: "Supplier has been onboarded",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SupplierStatus.onboarded.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SupplierStatus.onboarded.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SupplierStatus.onboarded.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.SupplierStatus.onboarded.body",
  },
  [SupplierStatus.terminated]: {
    status: SupplierStatus.terminated,
    header: "OnBoarded",
    title: "Terminated",
    intro: "Onboarding Terminated",
    body: "Supplier onboarded Terminated.",
    show: false,
    done: false,
    reject: false,
    terminate: true,
    step: null,
    headerLanguageKey:
      "static.process.SupplierSetupSteps.SupplierStatus.terminated.header",
    titleLanguageKey:
      "static.process.SupplierSetupSteps.SupplierStatus.terminated.title",
    introLanguageKey:
      "static.process.SupplierSetupSteps.SupplierStatus.terminated.intro",
    bodyLanguageKey:
      "static.process.SupplierSetupSteps.SupplierStatus.terminated.body",
  },
};

export const MigrateSetupSteps = {
  [MigratedTask.migrate]: {
    status: MigratedTask.migrate,
    header: "Pending",
    title: "Migrated",
    intro: "Supplier has been migrated from SAP",
    body: "Supplier has been migrated from SAP",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.MigratedTask.migrate.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.MigratedTask.migrate.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.MigratedTask.migrate.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.MigratedTask.migrate.body",
  },
  [MigrateProcess.supplier_accepted]: {
    status: MigrateProcess.supplier_accepted,
    header: "Pending",
    title: "Supplier Accepted Invitation",
    intro: "Supplier Accepted Invitation",
    body: "Supplier Accepted Invitation",
    show: true,
    done: false,
    reject: false,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.supplier_accepted.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.supplier_accepted.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.supplier_accepted.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.supplier_accepted.body",
  },
  [MigrateProcess.profile_draft]: {
    status: MigrateProcess.profile_draft,
    header: "Pending",
    title: "Supplier Draft",
    intro: "Supplier completed Supplier Draft",
    body: "Supplier completed Supplier Draft",
    show: true,
    done: false,
    reject: false,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.profile_draft.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.profile_draft.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.profile_draft.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.profile_draft.body",
  },
  [MigrateProcess.profile_resubmit]: {
    status: MigrateProcess.profile_resubmit,
    header: "Pending",
    title: "Supplier Draft",
    intro: "Supplier Resubmits profile",
    body: "Supplier Resubmits profile",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.profile_resubmit.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.profile_resubmit.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.profile_resubmit.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.profile_resubmit.body",
  },
  [MigrateProcess.SST_review]: {
    status: MigrateProcess.SST_review,
    header: "Pending",
    title: "AP SST Review",
    intro: "AP SST Team review has been completed",
    body: "AP SST Team review has been completed",
    show: true,
    done: false,
    reject: false,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.SST_review.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.SST_review.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.SST_review.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.SST_review.body",
  },
  [MigrateProcess.QC_review]: {
    status: MigrateProcess.QC_review,
    header: "Pending",
    title: "Final QC Review",
    intro: "AP QC Team review has been completed",
    body: "AP QC Team review has been completed",
    show: true,
    done: false,
    reject: false,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.QC_review.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.QC_review.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.QC_review.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.MigrateProcess.QC_review.body",
  },
  [RiskAssessmentStatus.SAP]: {
    status: RiskAssessmentStatus.SAP,
    header: "Pending",
    title: "Integrated with SAP",
    intro: "Supplier record has been setup in SAP platform",
    body: "Supplier record has been setup in SAP platform",
    show: true,
    done: false,
    reject: false,
    terminate: false,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.RiskAssessmentStatus.SAP.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.RiskAssessmentStatus.SAP.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.RiskAssessmentStatus.SAP.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.RiskAssessmentStatus.SAP.body",
  },
  [SupplierStatus.onboarded]: {
    status: SupplierStatus.onboarded,
    header: "OnBoarded",
    title: "Completed",
    intro: "Supplier has been onboarded",
    body: "Supplier has been onboarded",
    show: true,
    done: false,
    reject: false,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.SupplierStatus.onboarded.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.SupplierStatus.onboarded.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.SupplierStatus.onboarded.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.SupplierStatus.onboarded.body",
  },
  [SupplierStatus.terminated]: {
    status: SupplierStatus.terminated,
    header: "OnBoarded",
    title: "Terminated",
    intro: "Onboarding Terminated",
    body: "Supplier onboarded Terminated.",
    show: false,
    done: false,
    reject: false,
    terminate: true,
    step: null,
    headerLanguageKey:
      "static.process.MigrateSetupSteps.SupplierStatus.terminated.header",
    titleLanguageKey:
      "static.process.MigrateSetupSteps.SupplierStatus.terminated.title",
    introLanguageKey:
      "static.process.MigrateSetupSteps.SupplierStatus.terminated.intro",
    bodyLanguageKey:
      "static.process.MigrateSetupSteps.SupplierStatus.terminated.body",
  },
};

export enum RejectInfo {
  SST_Reject_intro = "AP SST team rejected the supplier profile record and pending more info from %s",
  SST_Reject_body = "AP SST team rejected the supplier profile record and pending more info from %s",
  QC_Reject_intro = "AP QC team rejected the supplier profile record and pending more info from AP SST",
  QC_Reject_body = "AP QC team rejected the supplier profile record and pending more info from AP SST",
}

export enum ApproveInfo {
  SST_Approve_intro = "AP SST Team review has been completed",
  SST_Approve_body = "AP SST Team review has been completed",
  QC_Approve_intro = "AP QC Team review has been completed",
  QC_Approve_body = "AP QC Team review has been completed",
}

export const MigrateStep = {
  title: "Migrated",
  intro: "Supplier has been migrated from SAP",
  body: "Supplier has been migrated from SAP",
};
export enum RiskScore {
  notApplicable = "Not Required - Exempted",
  completed = "Completed",
  pending = "Pending",
  notStart = "Not Start",
  red = "Red",
  yellow = "Yellow",
  green = "Green",
  normal = "Normal",
  aboveNormal = "Above Normal",
  high = "High",
  material = "Material",
  notMaterial = "Not material",
  ongoing = "On going",
}
