import { Injectable } from "@angular/core";
import { HttpService } from "src/app/services/api/http.service";
import { environment } from "../../../../environments/environment";
import { SRCMReportSearchDTO } from "src/app/interfaces/reportSRCM";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  isShowDrop = false;
  baseUrl = environment.gateway;
  stakeHolderUrl = environment.stakeholderGateway;

  constructor(private http: HttpService) {}

  generate<T>(dto: SRCMReportSearchDTO): Promise<any> {
    const url = `${this.baseUrl}/buyer/reportSRCM`;
    return this.http.PostPromise(url, dto);
  }

  generateSRCM<T>(dto: SRCMReportSearchDTO): Promise<any> {
    const url = `${this.stakeHolderUrl}/reportSRCM`;
    return this.http.PostPromise(url, dto);
  }

  downloadAPReport(data: any): Promise<any> {
    const url = `${this.baseUrl}/buyer/apReportDownload`;
    return this.http.PostPromise(url, data);
  }

  downloadNewAPReport(data: any): Promise<any> {
    const url = `${this.stakeHolderUrl}/apReportDownload`;
    return this.http.PostPromise(url, data);
  }

  downloadRawDataReport(data: any): Promise<any> {
    const url = `${this.baseUrl}/buyer/rawDataReportDownload`;
    return this.http.PostBlobPromise(url, data, "blob");
  }

  downloadNewRawDataReport(data: any): Promise<any> {
    const url = `${this.stakeHolderUrl}/rawDataReportDownload`;
    return this.http.PostBlobPromise(url, data, "blob");
  }

  downloadChangeAvanadeCodeReport(): Promise<any> {
    const url = `${this.baseUrl}/buyer/changeAvanadeCodeReportDownload`;
    return this.http.PostBlobPromise(url, {}, "blob");
  }

  downloadNewChangeAvanadeCodeReport(): Promise<any> {
    const url = `${this.stakeHolderUrl}/changeAvanadeCodeReportDownload`;
    return this.http.PostBlobPromise(url, {}, "blob");
  }

  downloadMetricsReport(data: any): Promise<any> {
    const url = `${this.baseUrl}/buyer/metricsReportDownload`;
    return this.http.PostBlobPromise(url, data, "blob");
  }

  downloadNewMetricsReport(data: any): Promise<any> {
    const url = `${this.stakeHolderUrl}/metricsReportDownload`;
    return this.http.PostBlobPromise(url, data, "blob");
  }

  auditTrailReportDownload(data: any): Promise<any> {
    const url = `${this.baseUrl}/buyer/auditTrailReportDownload`;
    return this.http.PostBlobPromise(url, data, "blob");
  }

  newAuditTrailReportDownload(data: any): Promise<any> {
    const url = `${this.stakeHolderUrl}/auditTrailReportDownload`;
    return this.http.PostBlobPromise(url, data, "blob");
  }

  downloadDSReport(data: any): Promise<any> {
    const url = `${this.baseUrl}/buyer/dsReportDownload`;
    return this.http.PostBlobPromise(url, data, "blob");
  }

  downloadNewDSReport(data: any): Promise<any> {
    const url = `${this.stakeHolderUrl}/dsReportDownload`;
    return this.http.PostBlobPromise(url, data, "blob");
  }
}
