import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { CommonService } from "../../../../services/common.service";
import { BuyerChangeRequestItem } from "../../../../interfaces/buyer";
// import { PaymentTermComponent } from './payment-term/payment-term.component'
import {
  DialogService,
  DialogType,
  ButtonItem,
  ButtonCommands,
} from "../../../../services/dialog.service";
import { resolve } from "q";
// import { CompanyComponent } from './company/company.component'
import { BuyerChangeRequestDTO } from "../../../../interfaces/changeRequest";
import { OnBoardingService } from "../../services/onboarding.service";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { HoldOn } from "src/app/interfaces/HoldOn";
import { HoldOnService } from "src/app/services/HoldOn";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-hold-request",
  templateUrl: "./hold-request.component.html",
  styleUrls: ["./hold-request.component.sass"],
})
export class HoldRequestComponent implements OnInit {
  @Input() isShowDialog: boolean;
  @Output() hiddenCRDialog: EventEmitter<boolean>;
  @Output() checkApplyHoldResult: EventEmitter<boolean>;

  changeRequestItems: any = [];
  isValidated: boolean;
  showTooltip: boolean;

  isSubmitDisabled = false;
  onHoldModel: HoldOn = {};
  whyHoldAttachments: any;

  OK: ButtonItem = {
    label: "OK",
    action: () => {
      resolve("ok");
    },
  };
  Close: ButtonItem = {
    label: "Close",
    action: () => {
      resolve("close");
    },
  };

  constructor(
    private dialogService: DialogService,
    private onboardingService: OnBoardingService,
    private holdOnService: HoldOnService,
    private authService: AuthService,
    private loadingService: LoadingService
  ) {
    this.hiddenCRDialog = new EventEmitter();
    this.checkApplyHoldResult = new EventEmitter();
  }

  async ngOnInit() {
    this.isValidated = false;
  }

  addAttachmentInfo(fileList) {
    this.whyHoldAttachments = fileList;
  }

  validate(): boolean {
    this.isValidated = true;
    if (!this.onHoldModel.Comments) {
      return false;
    }
    return true;
  }

  closeDialog() {
    this.isValidated = false;
    this.isShowDialog = false;
    this.hiddenCRDialog.emit(this.isShowDialog);
    this.onHoldModel.Comments = "";
  }

  async submit() {
    this.isSubmitDisabled = true;
    if (!this.validate()) {
      this.isSubmitDisabled = false;
      return false;
    }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.applyHoldOn}`;
    this.loadingService.openLoading(saveUrl);
    this.onHoldModel.CreatedBy = this.authService.passport.profile.email;
    this.onHoldModel.CreatedDate = new Date();
    this.onHoldModel.ExpirationDate = new Date(
      this.onHoldModel.CreatedDate.getTime() + 1000 * 60 * 60 * 24 * 7
    );
    this.onHoldModel.IsActive = true;
    this.onHoldModel.SupplierCode =
      this.onboardingService.supplierModel.Mapping.supplierCode;
    this.onHoldModel.Source = "Apply Hold";
    this.onHoldModel.Attachment = this.onHoldModel.Attachment;
    const attachmentList = [];
    this.onHoldModel.Attachment = JSON.stringify(this.whyHoldAttachments);
    const isSuccess = await this.holdOnService.saveHoldOn(this.onHoldModel);
    this.loadingService.closeLoading();
    if (isSuccess) {
      this.closeDialog();
      this.dialogService.confirm(
        DialogType.success,
        "The Apply Hold has been submitted successfully.",
        [this.OK],
        "Confirmed"
      );
    } else {
      this.closeDialog();
      this.dialogService.confirm(
        DialogType.warning,
        "Submit failed,please try again later.",
        [this.Close],
        "Failed"
      );
    }
    this.checkApplyHoldResult.emit(isSuccess);
    this.closeDialog();
    this.isSubmitDisabled = false;
    // location.reload()
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
