import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
} from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { RoleName } from "src/app/interfaces/buyer";

@Component({
  selector: "app-system-admin",
  templateUrl: "./system-admin.component.html",
  styleUrls: ["./system-admin.component.sass"],
})
export class SystemAdminComponent implements OnInit {
  @Input() enableClick = true;
  isShowDrop: boolean;
  isQC: boolean;
  isSST: boolean;
  isSystemAdmin: boolean;
  isBuyerAdmin: boolean;
  @ViewChild("abc")
  abc: ElementRef;

  @ViewChild("def")
  def: ElementRef;

  constructor(
    private authService: AuthService,
    private router: Router,
    private ele: ElementRef
  ) {}

  ngOnInit() {
    this.isShowDrop = this.isQC = this.isSST = this.isSystemAdmin = this.isBuyerAdmin = false;
  }

  @HostListener("document:keydown", ["$event"])
  documentClick(evt) {
    if (this.abc && evt.keyCode !== 13) {
      if (this.def.nativeElement.contains(evt.target)) {
        return;
      }
      if (this.abc.nativeElement.contains(evt.target)) {
        return;
      }
      this.isShowDrop = false;
    }
    if(evt.keyCode == 13 && evt.srcElement.nodeName === 'APP-SYSTEM-ADMIN') {
      this.isShowDrop = !this.isShowDrop;
    }
  }

  navigateTo(item: string) {
    // console.log(item);
    this.router.navigate([`${item}`]);
  }

  checkPermission() {
    if (environment.role === "buyer") {
      this.isSST = this.authService.passport.buyer.RoleCode === RoleName.APSupplierSetupTeam;
      this.isQC = this.authService.passport.buyer.RoleCode === RoleName.APQualityControl;
      this.isSystemAdmin = this.authService.passport.buyer.RoleCode === RoleName.SystemAdmin;
      this.isBuyerAdmin = this.authService.passport.buyer.RoleCode === RoleName.BuyerAdmin;
    }
  }
}
