import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BuyerUser } from "src/app/interfaces/buyer";
import { BuyerService } from "src/app/services/buyer.service";
import { ThemePalette } from "@angular/material/core";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { ManagementService } from "../../../services/management.service";
import { trim } from "src/app/utils";
import { environment } from "src/environments/environment";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";
import { PeoplePickerConfig } from "src/app/interfaces/peoplePickerConfig";

enum SupplierStatus {
  Active = "Active",
  Inactive = "Inactive",
}

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.sass"],
})
export class UserEditComponent implements OnInit, OnDestroy {
  SupplierStatus = SupplierStatus;
  color: ThemePalette = "primary";
  mode: string;
  mail: string;
  users: Array<BuyerUser> = [];
  roles = [];
  hasError: boolean;
  checkingDuplicateUser = false;
  currentUser: BuyerUser;
  currentRole;
  isValidated = true;
  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
  emailFormatValidated = true;
  config: PeoplePickerConfig = new PeoplePickerConfig();

  private get isCreate(): boolean {
    return !this.mode;
  }

  private get isView() {
    return this.mode === "view";
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private managementService: ManagementService,
    private dialogService: DialogService,
    private buyerService: BuyerService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    // this.isValidated = false
    this.mode = this.route.snapshot.paramMap.get("mode");
    this.mail = this.route.snapshot.paramMap.get("mail");
    if (this.isCreate) {
      this.managementService.title = "Create User Details";
    } else if (this.isView) {
      this.managementService.title = "View User Details";
    } else {
      this.managementService.title = "Edit User Details";
    }
    this.config.hide = false;
    this.config.name = "email";
    this.config.showType = "email";
    this.config.readOnly = !this.isCreate;
    this.managementService.changeToEditMode();

    this.buyerService.buyerUsers().then((data: any) => {
      this.users = data.data.buyerUsers;
      this.roles = data.data.buyerRoles;
      this.currentUser = this.isCreate
        ? {}
        : this.users.find((u) => u.Email === this.mail);
      this.currentRole = this.isCreate
        ? {}
        : this.roles.find((r: any) => r.code === this.currentUser.RoleCode);
      if (this.isCreate) {
        this.currentUser.Enabled = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.managementService.changeToViewMode();
  }

  async checkDuplicateUser() {
    if (this.checkingDuplicateUser) {
      return;
    }
    this.checkingDuplicateUser = true;
    const resp: any = await this.buyerService.userExists(
      this.currentUser.Email
    );
    this.checkingDuplicateUser = false;
    return resp.data;
  }

  async create() {
    await this.validate();
    if (!this.isValidated) {
      return;
    }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.buyerAddUser}`;
    this.loadingService.openLoading(saveUrl);
    if (this.isValidated) {
      this.buyerService.createUser(this.currentUser).then(() => {
        this.dialogService
          .dialog(
            "User profile created successfully!",
            ButtonCommands.Ok,
            DialogType.success
          )
          .then((d) => {
            this.router.navigate([`/buyer/management/users`]);
          });
      });
    }
  }

  async save() {
    // if (!this.isCreate) {
    //   this.buyerService.modifyUser(this.currentUser, this.currentUser.Email).then(data => {
    //     this.dialogService.dialog('User profile updated successfully!', ButtonCommands.Ok, DialogType.success)
    //       .then(d => {
    //         this.router.navigate([`/buyer/management/users`])
    //       })
    //   })
    // }
    await this.validate();
    if (!this.isValidated) {
      return;
    }
    if (this.isCreate) {
      this.create();
      return;
    }
    this.buyerService
      .modifyUser(this.currentUser, this.currentUser.Email)
      .then((data) => {
        this.dialogService
          .dialog(
            "User profile updated successfully!",
            ButtonCommands.Ok,
            DialogType.success
          )
          .then((d) => {
            this.router.navigate([`/buyer/management/users`]);
          });
      });
  }

  cancel() {
    this.managementService.searchChanged.emit("");
    this.router.navigate([`/buyer/management/users`]);
  }

  async validate() {
    this.isValidated = true;
    this.emailFormatValidated = this.emailPattern.test(this.currentUser.Email);
    if (this.currentUser.Name) {
      this.currentUser.Name = this.currentUser.Name.trim();
    }
    if (
      !this.currentUser.RoleCode ||
      !this.currentUser.Name ||
      !this.currentUser.Email ||
      !this.emailFormatValidated
    ) {
      this.isValidated = false;
      return;
    }
    if (this.currentUser.Email && this.isCreate) {
      const past = await this.checkDuplicateUser();
      this.isValidated = past;
      this.hasError = !past;
    }
  }

  eventHandler(value: string) {
    this.currentUser.Email = value;
  }

  checkValidate(value: boolean) {
    if (value) {
      this.validate();
    }
  }
}
