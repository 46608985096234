import {
  AfterViewInit,
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, of } from "rxjs";
import { catchError, debounceTime, map, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { BuyerService } from "src/app/services/buyer.service";

@Component({
  selector: "app-peoplepicker",
  templateUrl: "./peoplepicker.component.html",
  styleUrls: ["./peoplepicker.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class PeoplepickerComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  showTooltip = false;
  selectedText = "";
  cacheInputtedValue = "";
  peoplePickerList: Array<{ [key: string]: string }> = [];
  isInvalid = false;
  invalidMessage = "Invalid value, please search and select";
  isShowPopup = false;
  isFocus = false;
  isLoading = false;
  hasAdditionError = false;
  additionErrorInfo =
    "Unexpected network error, please reenter your search content";

  private subject = new Subject();
  private $observable = this.subject.asObservable().pipe(
    debounceTime(1500),
    switchMap((val) => {
      if (val) {
        this.isLoading = true;
        return this.buyerService
        .searchPeopleUsingGraphAPI(val, this.config["Level"]);
      } else {
        this.isLoading = false;
      }

      return [];
    })
  );
  @ViewChild("inputPanel") inputPanel: ElementRef;
  @ViewChild("dropPanel") dropPanel: ElementRef;

  constructor(private http: HttpClient, private buyerService: BuyerService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    // console.log("if people picker broken, comment here");

    const peoplePickerSubscribe = this.$observable.subscribe((result) => {
      this.isLoading = false;
      if (!result || !result.length) {
        this.isInvalid = true;
        this.invalidMessage = "No records found";
      }
      this.peoplePickerList = [];
      for (const obj of result) {
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            this.peoplePickerList.push({
              enterpriseId: key,
              displayName: obj[key],
              email: `${key}@accenture.com`,
            });
          }
        }
      }
      if (!!this.peoplePickerList && this.isFocus) {
        this.isShowPopup = !this.isInvalid;
      }
      if (result.Error) {
        this.hasAdditionError = true;
      }
    });

    this.subscriptions.push(peoplePickerSubscribe);
    this.setDisplayValueOnType(this.value);
  }

  ngAfterViewInit() {
    this.subscribeToValueChange(this.config.name, (v) => {
      this.setDisplayValueOnType(v);
    });
    if(this.config.name == 'BehalfEid'){
      this.subscribeToValueChange('IsEmployee', (v) => {
        if(!v){
          this.isInvalid = false;
          this.hasAdditionError = false;
          this.cacheInputtedValue = ""
          this.selectedText = ""
        }
      });
    }
  }

  getPeoplePicker(inputtedValue) {
    this.value = "";
    this.isInvalid = false;
    this.hasAdditionError = false;
    this.cacheInputtedValue = inputtedValue;
    if (
      this.config["showType"] === "email" &&
      inputtedValue &&
      inputtedValue.indexOf("@") > -1
    ) {
      const domain = inputtedValue.split("@")[1];
      if ("accenture.com".indexOf(domain) > -1) {
        inputtedValue = inputtedValue.split("@")[0];
      }
    }

    this.peoplePickerList = [];
    this.isFocus = true;
    this.subject.next(inputtedValue);
  }

  selectOne(eid) {
    this.setDisplayValueOnType(eid);
    this.value = eid;
    this.cacheInputtedValue = "";
    this.peoplePickerList = [];
    this.isShowPopup = false;
    this.formGroup.updateValueAndValidity();
  }

  setDisplayValueOnType(eid) {
    if (eid) {
      this.selectedText =
        this.config["showType"] === "email" ? eid + "@accenture.com" : eid;
    }
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (this.inputPanel) {
      if (this.inputPanel.nativeElement.contains(event.target)) {
        this.isFocus = true;
      } else {
        this.isFocus = false;
      }

      if (this.dropPanel) {
        if (
          this.inputPanel.nativeElement.contains(event.target) ||
          this.dropPanel.nativeElement.contains(event.target)
        ) {
          return;
        } else {
          this.isInvalid = !!this.cacheInputtedValue && !this.value;
          this.invalidMessage = "Invalid value, please search and select";
          this.isShowPopup = false;
        }
      } else if (this.inputPanel.nativeElement.contains(event.target)) {
        this.isShowPopup = !!this.selectedText;
      } else {
        this.isInvalid = !!this.cacheInputtedValue && !this.value;
        this.invalidMessage = "Invalid value, please search and select";
        this.isShowPopup = false;
      }
    }
  }
}
